<template>
  <v-container>
    <h1>Invite Users</h1>
    <v-combobox v-model='emails'
                :disabled="sending"
                label='Email Address'
                :error-messages="!emailsValid && emails.length > 0 ? 'One or more emails are invalid.' : ''"
                multiple filled chips
                hint='Enter up to 10 email addresses. Use the "Enter" key to add an email address to the list.'
                persistent-hint>
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            class="accent white--text"
            left
            :color="!validateEmail(data.item) ? 'error' : 'success'"
          >
            <v-icon color="white" v-if="validateEmail(data.item)">fas fa-check</v-icon>
            <v-icon color="white" v-else>fas fa-times</v-icon>
          </v-avatar>
          {{ data.item }}
          <v-icon @click="removeEmail(data.index)" small right>
            fas fa-times
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>

    <v-select label="Role"
              v-model="role"
              :disabled="sending"
              :items="roles"
              class="my-6"
              filled
              hint="Choose the role that the users will be assigned to. You can change this role later in the user settings."
              persistent-hint />

    <div class="mb-6">
      <a class="d-flex align-content-center" @click="showCustomEmail = !showCustomEmail">
        <v-icon v-if="!showCustomEmail" small>fa-chevron-right</v-icon>
        <v-icon v-else small>fa-chevron-down</v-icon>
        <span class="ml-2">Personalize email invite</span>
      </a>
      <div v-if="showCustomEmail">
        <v-textarea :disabled="sending" v-model="customEmailMessage" filled :placeholder="'Hi, \n\nWelcome to...'"/>
      </div>
    </div>

    <v-btn depressed :loading="sending" color="primary" @click="inviteUsers" :disabled="!emailsValid">Invite User</v-btn>
    <v-btn :to="{ name: 'OrganizationUsers' }" text class="ml-2">Cancel</v-btn>
  </v-container>
</template>
<script>
import { email } from '../../../library/Validators'
import { mapGetters } from 'vuex'
import event, { Events } from '../../../event'
import { Roles } from '@/js/Roles'

export default {
  name: 'InviteUsers',
  data: () => ({
    Roles,
    showCustomEmail: false,
    sending: false,
    emails: [],
    role: Roles.BASIC,
    customEmailMessage: '',
    roles: [
      { text: 'Basic - Standard access to manage patients and cases. Does not have access to site settings.', value: Roles.BASIC },
      { text: 'Clinic Staff - Clinic staff only has access to manage patient intake process.', value: Roles.CLINIC_STAFF },
      { text: 'Admin - Basic role plus administrative access.', value: Roles.ADMIN }
    ]
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    }),
    emailsValid () {
      if (this.emails.length === 0) {
        return false
      }

      let valid = true

      this.emails.forEach(e => {
        if (!this.validateEmail(e)) {
          valid = false
        }
      })

      return valid
    }
  },
  methods: {
    removeEmail (index) {
      this.emails.splice(index, 1)
    },
    validateEmail (value) {
      return email(value)
    },
    inviteUsers () {
      if (!this.emailsValid) {
        return
      }

      this.sending = true

      const promises = []

      this.emails.forEach((e) => {
        promises.push(this.$axios.post(`${this.url}/user/invite`, {
          email: e,
          role: this.role
        }))
      })

      Promise.all(promises)
        .then(() => {
          event.emit(Events.SUCCESS, 'Invites sent!')
          this.$router.push('/organization/settings/users')
        })
    }
  }
}
</script>
